<template>
  <div>
    <div ref="location-search" style="marginTop: 8px">
      <a-auto-complete @search="handleSearch" @select="onSearchSelect">
        <template slot="dataSource">
          <a-select-option
            v-for="(item, idx) in searchResults"
            :key="idx.toString()"
            >{{ item.formatted_address }}</a-select-option
          >
        </template>
      </a-auto-complete>
    </div>
    <div ref="gmaps-container" class="gmaps-container" />
    <div class="gmaps-footer">
      <slot ref="map-footer" name="map-footer"></slot>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AutoComplete, Select } from "ant-design-vue";
import debounce from "lodash/debounce";
import gmapsInit from "../utils/gmaps";

Vue.use(AutoComplete);
Vue.use(Select);

let resolveMapInitPromise;
let rejectMapInitPromise;

export const defaultMapCenter = {
  lat: 48.816063,
  lng: 2.290236,
};

const mapInitPromise = new Promise((resolve, reject) => {
  resolveMapInitPromise = resolve;
  rejectMapInitPromise = reject;
});

const styles = {
  default: null,
  hide: [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ],
};

export default {
  name: "gmap",
  data() {
    return {
      google: null,
      map: null,
      geocoder: null,
      searchResults: [],
      mapInitPromise,
    };
  },
  async mounted() {
    if (this.$store.state.preferences.lastFetch === null) {
      this.$store.dispatch("fetchUserPreferences");
    }
    let mapMode = this.$store.getters.getUserPreferences().mapMode;

    try {
      this.google = await gmapsInit();
      this.map = new this.google.maps.Map(this.$refs["gmaps-container"]);
      this.geocoder = new this.google.maps.Geocoder();

      this.map.setOptions({
        fullscreenControl: false,
        streetViewControl: false,
        styles: styles["hide"],
        mapTypeId: mapMode.toLowerCase(),
        tilt: 0
      });

      this.$nextTick(() => {
        this.map.controls[this.google.maps.ControlPosition.TOP_CENTER].push(
          this.$refs["location-search"]
        );
        resolveMapInitPromise();
      });
    } catch (error) {
      console.log(error);
      rejectMapInitPromise();
    }
  },
  methods: {
    handleSearch: debounce(function(value) {
      this.geocoder.geocode(
        {
          address: value,
        },
        (results, status) => {
          if (status == "OK") {
            this.searchResults = [...results];
          } else {
            console.log("Error while searching for address");
          }
        }
      );
    }, 500),
    onSearchSelect(value) {
      const result = this.searchResults[parseInt(value)];
      this.map.setCenter(result.geometry.location);
    },
  },
};
</script>

<style lang="less" scoped>
.gmaps-container {
  display: block;
  height: 35rem;
  margin-top: 0.8rem;

  @media screen and (min-device-width: 992) {
    width: 150%;
  }
  
  @media screen and (min-device-width: 1200px) {
    width: 120%;
  }

  @media screen and (min-device-width: 1600px) {
    width: 135%;
    height: 42.5rem;
  }
}
.gmaps-footer {
  padding: 1.5rem;
  width: 95rem;
}
</style>
