<template>
  <div>
    <a-row
      type="flex"
      align="middle"
      :gutter="16"
    >
      <a-col>
        <a-space>
          <label class="label-flightplan">
            {{ $t('flightplans.selectedFlightplan') }}: 
          </label>
          <a-select
            @change="onFlightPlanChanged"
            :value="currentFlightPlanId"
          >
            <a-select-option
              v-for="fp in flightPlans"
              v-bind:key="fp.id"
              :value="fp.id"
            >
              {{ fp.name }}
            </a-select-option>
          </a-select>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import Vue from 'vue'
import { Col, Row, Input, Select, Button, Space } from 'ant-design-vue'

Vue.use(Col)
Vue.use(Row)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)
Vue.use(Space)

export default {
  name: 'flightplans-selector',
  computed: {
    flightPlans () {
      return this.$store.state.flightPlans.flightPlans
    },
    currentFlightPlanId () {
      return this.$store.state.flightPlans.currentFlightPlanId
    }
  },
  methods: {
    onFlightPlanChanged (id) {
      this.$store.dispatch('setCurrentFlightPlanId', id)
    }
  },
  mounted () {
    if (this.$store.state.flightPlans.lastFetch === null) {
      this.$store.dispatch('fetchFlightPlans')
    }
  }
}
</script>

<style lang="less" scoped>
.label-flightplan {
  font-weight: bold;
}
.column-select-flightplan {
  margin-top: -1%;
}
.ant-select {
  width: 200px;
}
</style>